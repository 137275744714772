@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

.group-conditions .disabled {
  display: none;
}

.padding-0 {
  padding: 0px;
}

.page-content {
  padding: 8px 12px 24px;
}

.select2-container--default .select2-selection--single {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.rule-value-container select, .rule-operator-container select, .rule-filter-container select {
  font-size: 11px;
  max-width: 400px;
}

.rule_definition_container .select2-container {
  font-size: 11px;
  line-height: 12px;
  max-width: 400px;
}

.dataTable tbody tr.selected.current_selected td {
  background-color: #003f33;
  color: white;
}

h4.loadingWidgetHeader {
  line-height: 36px;
  padding: 0;
  margin: 0;
  display: inline;
}

li.active span.title {
  //  font-weight: bold;
}

.form-control {
  padding: 5px;
}

.wizard-table .form-control {
  padding: 2px;
  height: 23px;
  max-height: 23px;
  font-size: 11px;
}

.ajax-loading-icon.blue {
  color: #003f33 !important;
}

.dz-progress {
  display: none;
}

.bootbox .modal-dialog {
  width: 400px !important;
}

.legend-box {
  width: 16px;
  height: 16px;
  border: 1px solid grey;
}

.page-header h1 {
  color: #003f33;
}

.td-right-bordered {
  border-right: 1px solid grey !important
}

.td-left-bordered {
  border-left: 1px solid grey !important
}

.td-top-bordered {
  border-top: 1px solid grey !important
}

.td-bottom-bordered {
  border-bottom: 1px solid grey !important
}

.dataTables_wrapper .select-info {
  display: none;
}

.custom-fieldset {
  padding: 15px;
}
.custom-legend {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
  padding: 0 20px;
  width: auto;
}
.fieldset-border-green {
  border: 1px solid #336300;
}
.fieldset-border-blue {
  border: 1px solid #005270;
}
.fieldset-border-yellow {
  border: 1px solid #eaa400;
}
.fieldset-border-purple {
  border: 1px solid #a069c3;
}

.blm-color-green {
  color: #336300;
}
.blm-color-blue {
  color: #005270;
}
.blm-color-yellow {
  color: #eaa400;
}
.blm-color-purple {
  color: #a069c3;
}

/***** DASHBOARD *****/
.db-box {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 30px;
  text-align: center;

  small {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
  }

  a {
    color: white;
  }
}

.db-box-blue {
  background: #005270;
}
.db-box-green {
  background: #336300;
}
.db-box-yellow {
  background: #eaa400;
}
.db-box-red {
  background: #e34747;
}

.db-search-input {
  margin: 10px 0 0 0;
  padding: 5px;
  width: 100%;
  border: 1px solid #336300;
  border-radius: 5px;
}

/***** CALENDAR *****/
.event_available_for_hotline {
  background: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 10px,
      rgba(0, 0, 0, 0.15) 10px,
      rgba(0, 0, 0, 0.15) 20px
  );
}
.event_tentative {
  opacity: 0.6;
}
.event_training_class {
  border: 1px solid red !important;
}
.fc-license-message {
  display: none !important;
}

/** CHARTS */
.c3 svg {
  width: 100%;
  height: 100%;
}

.select2-container--default .select2-selection .select2-selection__clear {
  right: 0px;
}

.badge-danger, .badge.badge-danger, .label-danger, .label.label-danger {
  height: auto !important;
  white-space: inherit;
}